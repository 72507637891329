import type { SessionErrorHandler } from '../tastyworks'
import HttpClient from '../tastyworks/http'
import type Logger from '../tastyworks/logger'
import { AcatLinkTokenService } from './services/acat-link-token'
import { AcatRequestService } from './services/acat-request'
import { AccountService } from './services/account'
import { AccountBeneficiaryService } from './services/account-beneficiary'
import { AccountDocumentService } from './services/account-documents'
import { AccountFuturesTradingRequestService } from './services/account-futures-trading-request'
import { AccountOpeningApplicationService } from './services/account-opening-application'
import { AccountPortfolioSubscriptionService } from './services/account-portfolio-subscription'
import { AccountTypeService } from './services/account-type'
import { AchLinkTokenService } from './services/ach-link-token'
import { AchRelationshipService } from './services/ach-relationship'
import { AdvancedTradingPermissionService } from './services/advanced-trading-permission'
import { AdvancedTradingRequestService } from './services/advanced-trading-request'
import { AssetTransferExternalAccountHoldingsService } from './services/asset-transfer-external-account-holdings'
import { AssetTransferExternalInstitutionService } from './services/asset-transfer-external-institution'
import { AutotradeOperatorService } from './services/autotrade-operator'
import { AutotradePositionService } from './services/autotrade-position'
import { BalanceService } from './services/balance'
import { BondService } from './services/bond'
import { CashRestrictionService } from './services/cash-restriction'
import { CastleIncidentService } from './services/castle-incident'
import { CommunicationChannelConsentsService } from './services/communication-channel-consent'
import { CommunicationPreferenceService } from './services/communication-preference'
import { ConfirmationDocumentService } from './services/confirmation-document'
import { CustomerService } from './services/customer'
import { CustomerAccountAgreementService } from './services/customer-account-agreement'
import { CustomerAccountPortfolioSubscriptionService } from './services/customer-account-portfolio-subscription'
import { CustomerAuthorityService } from './services/customer-authority'
import { CustomerDocumentService } from './services/customer-document'
import { CustomerPortfolioEntitlementService } from './services/customer-portfolio-entitlements'
import { DepositService } from './services/deposit'
import { DepositAvailableBuyingPowerService } from './services/deposit-available-buying-power'
import { DepositIraConstraintService } from './services/deposit-ira-constraint'
import { DigitalAssetClearingAddressService } from './services/digital-asset-clearing-address'
import { DigitalAssetCustomerAddressService } from './services/digital-asset-customer-address'
import { DigitalAssetDepositService } from './services/digital-asset-deposit'
import { DigitalAssetPermissionService } from './services/digital-asset-permission'
import { DigitalAssetWithdrawalService } from './services/digital-asset-withdrawal'
import { DividendReinvestmentRequestService } from './services/dividend-reinvestment-request'
import { EmailConfirmationService } from './services/email-confirmation'
import { EmailReconfirmationService } from './services/email-reconfirmation'
import { EntityAuthorityService } from './services/entity-authority'
import { EntityMigrationService } from './services/entity-migration'
import { EntitySuitabilityService } from './services/entity-suitability'
import { EquifaxService } from './services/equifax'
import { EquityOfferingService } from './services/equity-offering'
import { ExchangeAffiliationService } from './services/exchange-affiliations'
import { ExpirationMonitoringEntryService } from './services/expiration-monitoring-entry'
import { ExternalAffiliateCommissionService } from './services/external-affiliate-commission'
import { ExternalTransactionService } from './services/external-transaction'
import { FullyPaidSecuritiesLendingRequestService } from './services/fully-paid-securities-lending-request'
import { FuturesAgreementRecordService } from './services/futures-agreement-record'
import { FuturesTradingRequestService } from './services/futures-trading-request'
import { GeneralDocumentService } from './services/general-document'
import { InternalCashTransferService } from './services/internal-cash-transfer'
import { InvitationService } from './services/invitation'
import { MobileConfirmationService } from './services/mobile-confirmation'
import { NetLiqHistoryService } from './services/net-liq-history'
import { OauthService } from './services/oauth'
import { OpenApiAccessEntitlementService } from './services/open-api-access-entitlement'
import { OptionsLevelService } from './services/options-level'
import { PasswordService } from './services/password'
import { PdtResetRequestService } from './services/pdt-reset-request'
import { PermittedAccountTypeService } from './services/permitted-account-types'
import { PreferencesService } from './services/preferences'
import { RecurringDepositService } from './services/recurring-deposit'
import { ReferralService } from './services/referrals'
import { RequiredAgreementDocumentsService } from './services/required-agreement-documents'
import { SecurityQuestionService } from './services/security-question'
import { SessionService } from './services/session'
import { StatementDocumentService } from './services/statement-document'
import { SuitabilityService } from './services/suitability'
import { SupportingDocumentRequestService } from './services/supporting-document-request'
import { SupportingDocumentVerificationService } from './services/supporting-document-verification'
import { TaxDocumentService } from './services/tax-document'
import { TradingStatusService } from './services/trading-status'
import { TrustedContactService } from './services/trusted-contact'
import { TwoFactorMethodService } from './services/two-factor-method'
import { TwoFactorStatusService } from './services/two-factor-status'
import { UserService } from './services/user'
import { UserAuthenticatorService } from './services/user-authenticator'
import { W8BenService } from './services/w-8ben'
import { WebPushService } from './services/web-push'
import { WithdrawalService } from './services/withdrawal'
import { WithdrawalFeeService } from './services/withdrawal-fee'
import { WithdrawalIraConstraintService } from './services/withdrawal-ira-constraint'
import { WithdrawalLimitService } from './services/withdrawal-limit'
import type { TwRestSession } from './session'
import { VastClient } from './vast-client'

export class Client {
  private readonly accountNumberClient: HttpClient

  private readonly baseHttpClient: HttpClient

  private readonly customerMeClient: HttpClient

  private readonly userMeClient: HttpClient

  readonly baseUrl: string

  readonly twSession: TwRestSession

  // [Start] Keep entries sorted by alpha

  readonly acatLinkTokenService: AcatLinkTokenService

  readonly acatRequestService: AcatRequestService

  readonly accountBeneficiaryService: AccountBeneficiaryService

  readonly accountOpeningApplicationService: AccountOpeningApplicationService

  readonly accountPortfolioSubscriptionService: AccountPortfolioSubscriptionService

  readonly accountService: AccountService

  readonly accountDocumentService: AccountDocumentService

  readonly accountFuturesTradingRequestService: AccountFuturesTradingRequestService

  readonly accountTypeService: AccountTypeService

  readonly achLinkTokenService: AchLinkTokenService

  readonly achRelationshipService: AchRelationshipService

  readonly advancedTradingPermissionService: AdvancedTradingPermissionService

  readonly advancedTradingRequestService: AdvancedTradingRequestService

  readonly assetTransferExternalAccountHoldingsService: AssetTransferExternalAccountHoldingsService

  readonly assetTransferExternalInstitutionService: AssetTransferExternalInstitutionService

  readonly autotradeOperatorService: AutotradeOperatorService

  readonly autotradePositionService: AutotradePositionService

  readonly balanceService: BalanceService

  readonly bondService: BondService

  readonly castleIncidentService: CastleIncidentService

  readonly cashRestrictionService: CashRestrictionService

  readonly communicationChannelConsentsService: CommunicationChannelConsentsService

  readonly communicationPreferenceService: CommunicationPreferenceService

  readonly confirmationDocumentService: ConfirmationDocumentService

  readonly customerAccountAgreementService: CustomerAccountAgreementService

  readonly customerAccountPortfolioSubscriptionService: CustomerAccountPortfolioSubscriptionService

  readonly customerAuthorityService: CustomerAuthorityService

  readonly customerPortfolioEntitlementService: CustomerPortfolioEntitlementService

  readonly customerDocumentService: CustomerDocumentService

  readonly customerService: CustomerService

  readonly depositService: DepositService

  readonly depositAvailableBuyingPowerService: DepositAvailableBuyingPowerService

  readonly depositIraConstraintService: DepositIraConstraintService

  readonly digitalAssetPermissionService: DigitalAssetPermissionService

  readonly digitalAssetClearingAddressService: DigitalAssetClearingAddressService

  readonly digitalAssetCustomerAddressService: DigitalAssetCustomerAddressService

  readonly digitalAssetDepositService: DigitalAssetDepositService

  readonly digitalAssetWithdrawalService: DigitalAssetWithdrawalService

  readonly dividendReinvestmentRequestService: DividendReinvestmentRequestService

  readonly emailConfirmationService: EmailConfirmationService

  readonly entityAuthorityService: EntityAuthorityService

  readonly entitySuitabilityService: EntitySuitabilityService

  readonly equifaxService: EquifaxService

  readonly emailReconfirmationService: EmailReconfirmationService

  readonly entityMigrationService: EntityMigrationService

  readonly equityOfferingService: EquityOfferingService

  readonly exchangeAffiliationService: ExchangeAffiliationService

  readonly expirationMonitoringEntryService: ExpirationMonitoringEntryService

  readonly externalAffiliateCommissionService: ExternalAffiliateCommissionService

  readonly externalTransactionService: ExternalTransactionService

  readonly oauthService: OauthService

  readonly openApiAccessEntitlementService: OpenApiAccessEntitlementService

  readonly fullyPaidSecuritiesLendingRequestService: FullyPaidSecuritiesLendingRequestService

  readonly futuresAgreementRecordService: FuturesAgreementRecordService

  readonly futuresTradingRequestService: FuturesTradingRequestService

  readonly generalDocumentService: GeneralDocumentService

  readonly internalCashTransferService: InternalCashTransferService

  readonly invitationService: InvitationService

  readonly mobileConfirmationService: MobileConfirmationService

  readonly netLiqHistoryService: NetLiqHistoryService

  readonly optionsLevelService: OptionsLevelService

  readonly passwordService: PasswordService

  readonly pdtResetRequestService: PdtResetRequestService

  readonly permittedAccountTypeService: PermittedAccountTypeService

  readonly preferencesService: PreferencesService

  readonly referralService: ReferralService

  readonly recurringDepositService: RecurringDepositService

  readonly requiredAgreementDocumentsService: RequiredAgreementDocumentsService

  readonly securityQuestionService: SecurityQuestionService

  readonly sessionService: SessionService

  readonly statementDocumentService: StatementDocumentService

  readonly suitabilityService: SuitabilityService

  readonly supportingDocumentRequestService: SupportingDocumentRequestService

  readonly supportingDocumentVerificationService: SupportingDocumentVerificationService

  readonly taxDocumentService: TaxDocumentService

  readonly tradingStatusService: TradingStatusService

  readonly trustedContactService: TrustedContactService

  readonly twoFactorMethodService: TwoFactorMethodService

  readonly twoFactorStatusService: TwoFactorStatusService

  readonly userAuthenticatorService: UserAuthenticatorService

  readonly userService: UserService

  readonly vastClient: VastClient

  readonly webPushService: WebPushService

  readonly w8BenService: W8BenService

  readonly withdrawalFeeService: WithdrawalFeeService

  readonly withdrawalIraConstraintService: WithdrawalIraConstraintService

  readonly withdrawalLimitService: WithdrawalLimitService

  readonly withdrawalService: WithdrawalService

  // [End] Keep entries sorted by alpha

  constructor(
    baseUrl: string,
    vastBaseUrl: string,
    logger: Logger,
    fetcher: typeof fetch,
    sessionErrorHandler: SessionErrorHandler,
    twSession: TwRestSession
  ) {
    this.baseUrl = baseUrl
    this.twSession = twSession

    this.baseHttpClient = new HttpClient(
      logger,
      fetcher,
      baseUrl,
      this.twSession,
      sessionErrorHandler
    )
    this.vastClient = new VastClient(
      vastBaseUrl,
      logger,
      fetcher,
      sessionErrorHandler,
      this.twSession
    )
    this.accountNumberClient = this.baseHttpClient.nested(
      'accounts/:accountNumber'
    )
    this.customerMeClient = this.baseHttpClient.nested(
      'customers/:customerId',
      { customerId: 'me' }
    )
    this.userMeClient = this.baseHttpClient.nested('users/:userId', {
      userId: 'me'
    })

    this.userMeClient = this.baseHttpClient.nested('users/me')

    this.acatLinkTokenService = new AcatLinkTokenService(this.customerMeClient)
    this.acatRequestService = new AcatRequestService(this.accountNumberClient)
    this.accountBeneficiaryService = new AccountBeneficiaryService(
      this.accountNumberClient
    )
    this.accountDocumentService = new AccountDocumentService(
      this.baseHttpClient
    )
    this.accountFuturesTradingRequestService =
      new AccountFuturesTradingRequestService(this.accountNumberClient)
    this.accountOpeningApplicationService =
      new AccountOpeningApplicationService(
        this.baseHttpClient,
        this.customerMeClient
      )
    this.accountPortfolioSubscriptionService =
      new AccountPortfolioSubscriptionService(this.accountNumberClient)
    this.castleIncidentService = new CastleIncidentService(this.baseHttpClient)
    this.customerAccountPortfolioSubscriptionService =
      new CustomerAccountPortfolioSubscriptionService(this.customerMeClient)
    this.customerPortfolioEntitlementService =
      new CustomerPortfolioEntitlementService(this.customerMeClient)
    this.accountService = new AccountService(this.customerMeClient)
    this.accountTypeService = new AccountTypeService(this.baseHttpClient)
    this.achLinkTokenService = new AchLinkTokenService(this.customerMeClient)
    this.achRelationshipService = new AchRelationshipService(
      this.accountNumberClient
    )
    this.advancedTradingPermissionService =
      new AdvancedTradingPermissionService(this.accountNumberClient)
    this.advancedTradingRequestService = new AdvancedTradingRequestService(
      this.accountNumberClient
    )
    this.assetTransferExternalAccountHoldingsService =
      new AssetTransferExternalAccountHoldingsService(this.customerMeClient)
    this.assetTransferExternalInstitutionService =
      new AssetTransferExternalInstitutionService(this.customerMeClient)
    this.autotradeOperatorService = new AutotradeOperatorService(
      this.customerMeClient
    )
    this.autotradePositionService = new AutotradePositionService(
      this.accountNumberClient
    )
    this.balanceService = new BalanceService(this.accountNumberClient)
    this.bondService = new BondService(this.baseHttpClient)
    this.cashRestrictionService = new CashRestrictionService(
      this.accountNumberClient
    )
    this.confirmationDocumentService = new ConfirmationDocumentService(
      this.accountNumberClient
    )
    this.communicationChannelConsentsService =
      new CommunicationChannelConsentsService(
        this.baseHttpClient,
        this.customerMeClient
      )
    this.communicationPreferenceService = new CommunicationPreferenceService(
      this.customerMeClient
    )
    this.customerAccountAgreementService = new CustomerAccountAgreementService(
      this.customerMeClient
    )
    this.customerAuthorityService = new CustomerAuthorityService(
      this.accountNumberClient
    )
    this.customerDocumentService = new CustomerDocumentService(
      this.customerMeClient
    )
    this.customerService = new CustomerService(
      this.baseHttpClient,
      this.customerMeClient
    )
    this.depositService = new DepositService(this.accountNumberClient)
    this.depositAvailableBuyingPowerService =
      new DepositAvailableBuyingPowerService(this.accountNumberClient)
    this.depositIraConstraintService = new DepositIraConstraintService(
      this.accountNumberClient
    )
    this.digitalAssetPermissionService = new DigitalAssetPermissionService(
      this.accountNumberClient
    )
    this.digitalAssetClearingAddressService =
      new DigitalAssetClearingAddressService(this.accountNumberClient)
    this.digitalAssetCustomerAddressService =
      new DigitalAssetCustomerAddressService(this.accountNumberClient)
    this.digitalAssetDepositService = new DigitalAssetDepositService(
      this.accountNumberClient
    )
    this.digitalAssetWithdrawalService = new DigitalAssetWithdrawalService(
      this.accountNumberClient
    )
    this.dividendReinvestmentRequestService =
      new DividendReinvestmentRequestService(this.accountNumberClient)
    this.emailConfirmationService = new EmailConfirmationService(
      this.baseHttpClient
    )
    this.entityAuthorityService = new EntityAuthorityService(
      this.customerMeClient
    )
    this.entitySuitabilityService = new EntitySuitabilityService(
      this.customerMeClient
    )
    this.equifaxService = new EquifaxService(this.customerMeClient)
    this.emailReconfirmationService = new EmailReconfirmationService(
      this.customerMeClient
    )
    this.entityMigrationService = new EntityMigrationService(
      this.customerMeClient
    )
    this.equityOfferingService = new EquityOfferingService(this.baseHttpClient)
    this.exchangeAffiliationService = new ExchangeAffiliationService(
      this.customerMeClient
    )
    this.expirationMonitoringEntryService =
      new ExpirationMonitoringEntryService(this.accountNumberClient)
    this.externalTransactionService = new ExternalTransactionService(
      this.accountNumberClient
    )
    this.externalAffiliateCommissionService =
      new ExternalAffiliateCommissionService(this.userMeClient)
    this.fullyPaidSecuritiesLendingRequestService =
      new FullyPaidSecuritiesLendingRequestService(this.accountNumberClient)
    this.futuresAgreementRecordService = new FuturesAgreementRecordService(
      this.customerMeClient
    )
    this.futuresTradingRequestService = new FuturesTradingRequestService(
      this.baseHttpClient
    )
    this.generalDocumentService = new GeneralDocumentService(
      this.baseHttpClient
    )
    this.internalCashTransferService = new InternalCashTransferService(
      this.accountNumberClient
    )
    this.invitationService = new InvitationService(this.baseHttpClient)
    this.mobileConfirmationService = new MobileConfirmationService(
      this.userMeClient,
      this.customerMeClient
    )
    this.netLiqHistoryService = new NetLiqHistoryService(
      this.accountNumberClient
    )
    this.oauthService = new OauthService(this.baseHttpClient, this.userMeClient)
    this.openApiAccessEntitlementService = new OpenApiAccessEntitlementService(
      this.baseHttpClient
    )
    this.optionsLevelService = new OptionsLevelService(
      this.accountNumberClient,
      this.accountService,
      this.baseHttpClient
    )
    this.passwordService = new PasswordService(this.baseHttpClient)
    this.pdtResetRequestService = new PdtResetRequestService(
      this.accountNumberClient
    )
    this.permittedAccountTypeService = new PermittedAccountTypeService(
      this.customerMeClient
    )
    this.preferencesService = new PreferencesService(this.baseHttpClient)
    this.referralService = new ReferralService(this.baseHttpClient)
    this.recurringDepositService = new RecurringDepositService(
      this.accountNumberClient
    )
    this.requiredAgreementDocumentsService =
      new RequiredAgreementDocumentsService(this.customerMeClient)
    this.securityQuestionService = new SecurityQuestionService(
      this.userMeClient
    )
    this.sessionService = new SessionService(this.baseHttpClient, logger)
    this.statementDocumentService = new StatementDocumentService(
      this.accountNumberClient
    )
    this.suitabilityService = new SuitabilityService(this.customerMeClient)
    this.supportingDocumentRequestService =
      new SupportingDocumentRequestService(this.customerMeClient)
    this.supportingDocumentVerificationService =
      new SupportingDocumentVerificationService(this.customerMeClient)
    this.taxDocumentService = new TaxDocumentService(this.accountNumberClient)
    this.tradingStatusService = new TradingStatusService(
      this.accountNumberClient
    )
    this.trustedContactService = new TrustedContactService(
      this.customerMeClient
    )
    this.twoFactorMethodService = new TwoFactorMethodService(this.userMeClient)
    this.twoFactorStatusService = new TwoFactorStatusService(this.userMeClient)
    this.userAuthenticatorService = new UserAuthenticatorService(
      this.userMeClient
    )
    this.userService = new UserService(this.baseHttpClient, this.userMeClient)

    this.w8BenService = new W8BenService(this.customerMeClient)

    this.webPushService = new WebPushService(this.customerMeClient)

    this.withdrawalFeeService = new WithdrawalFeeService(
      this.accountNumberClient
    )
    this.withdrawalIraConstraintService = new WithdrawalIraConstraintService(
      this.accountNumberClient
    )
    this.withdrawalLimitService = new WithdrawalLimitService(
      this.accountNumberClient
    )
    this.withdrawalService = new WithdrawalService(this.accountNumberClient)

    // TODO: General mapping for document services.
    // /account-documents - AccountTypeDocumentService
    // /customers/:customer_id/supporting-documents - SupportingDocumentService
    // /account-opening-application/:account_opening_application_id/documents -AccountOpeningApplicationDocumentService
  }
}

import _ from 'lodash'
import type { SchemaDeSer, Updater } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { ArrayMap } from './util/collection'
import type { JsonHelper, JsonKeyExtractor } from './util/json'

export enum MARGIN_TYPES {
  MARGIN = 'Margin',
  CASH = 'Cash'
}

export enum ACCOUNT_TYPES {
  BENEFICIARY_ROTH_IRA = 'Beneficiary Roth IRA',
  BENEFICIARY_TRADITIONAL_IRA = 'Beneficiary Traditional IRA',
  BUSINESS = 'Business',
  INDIVIDUAL = 'Individual',
  JOINT_TENANTS_IN_COMMON = 'Joint Tenants in Common',
  JOINT_WROS = 'Joint Tenants with Rights of Survivorship',
  ROTH_IRA = 'Roth IRA',
  SEP_IRA = 'SEP',
  TRADITIONAL_IRA = 'Traditional IRA',
  TRUST = 'Trust'
}

export enum ACCOUNT_TYPE_CHOICES {
  INDIVIDUAL_BROKERAGE = 'individualBrokerage',
  RETIREMENT = 'retirement',
  JOINT = 'joint',
  BUSINESS_OR_TRUST = 'businessOrTrust'
}

export const JOINT_ACCOUNT_TYPE_CHOICES = {
  JOINT_TENANTS_IN_COMMON: _.camelCase(ACCOUNT_TYPES.JOINT_TENANTS_IN_COMMON),
  JOINT_WROS: _.camelCase(ACCOUNT_TYPES.JOINT_WROS)
}

export const INTERNAL_TRANSFER_ELIGIBLE_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.BENEFICIARY_ROTH_IRA,
  ACCOUNT_TYPES.BENEFICIARY_TRADITIONAL_IRA,
  ACCOUNT_TYPES.INDIVIDUAL,
  ACCOUNT_TYPES.ROTH_IRA,
  ACCOUNT_TYPES.TRADITIONAL_IRA,
  ACCOUNT_TYPES.SEP_IRA
]

export enum BUSINESS_ACCOUNT_TYPES {
  LLC = 'LLC',
  C_CORP = 'C Corp',
  S_CORP = 'S Corp',
  PARTNERSHIP = 'Partnership'
}

export enum RETIREMENT_ACCOUNT_CHOICES {
  INDIVIDUAL = 'individual',
  BENEFICIARY_INHERITED = 'beneficiary / Inherited'
}

export enum JOINT_ACCOUNT_CHOICES {
  JOINT_TENANTS_IN_COMMON = 'jointTenantsInCommon',
  JOINT_WROS = 'jointTenantsWithRightsOfSurvivorship'
}

export enum IRA_INDIVIDUAL_ACCOUNT_TYPE_CHOICES {
  ROTH_IRA = 'rothIra',
  TRADITIONAL_IRA = 'traditionalIra',
  SEP_IRA = 'sep'
}

export enum IRA_BENEFICIARY_OR_INHERITED_ACCOUNT_TYPE_CHOICES {
  BENEFICIARY_TRADITIONAL_IRA = 'beneficiaryTraditionalIra',
  BENEFICIARY_ROTH_IRA = 'beneficiaryRothIra'
}

export const RETIREMENT_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.BENEFICIARY_ROTH_IRA,
  ACCOUNT_TYPES.BENEFICIARY_TRADITIONAL_IRA,
  ACCOUNT_TYPES.ROTH_IRA,
  ACCOUNT_TYPES.SEP_IRA,
  ACCOUNT_TYPES.TRADITIONAL_IRA
]

export const IRA_INDIVIDUAL = [
  ACCOUNT_TYPES.ROTH_IRA,
  ACCOUNT_TYPES.TRADITIONAL_IRA,
  ACCOUNT_TYPES.SEP_IRA
]

export const ROTH_IRA_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.ROTH_IRA,
  ACCOUNT_TYPES.BENEFICIARY_ROTH_IRA
]

export const TRADITIONAL_IRA_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.TRADITIONAL_IRA,
  ACCOUNT_TYPES.BENEFICIARY_TRADITIONAL_IRA
]

export const BENEFICIARY_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.BENEFICIARY_TRADITIONAL_IRA,
  ACCOUNT_TYPES.BENEFICIARY_ROTH_IRA
]

export const JOINT_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.JOINT_TENANTS_IN_COMMON,
  ACCOUNT_TYPES.JOINT_WROS
]

export const ENTITY_ACCOUNT_TYPES = [
  ACCOUNT_TYPES.BUSINESS,
  ACCOUNT_TYPES.TRUST
]

export class MarginType {
  name = ''
  isMargin = false
}

export const MARGIN_TYPE_DESER: SchemaDeSer<MarginType> =
  new SchemaDeSerBuilder(MarginType)
    .ofString('name')
    .ofBoolean('isMargin')
    .toDeSer()

export class AccountType {
  name = ''
  description = ''
  isTaxAdvantaged = false
  hasMultipleOwners = false
  isPubliclyAvailable = false
  marginTypes: MarginType[] = []
}

export const ACCOUNT_TYPE_DESER: SchemaDeSer<AccountType> =
  new SchemaDeSerBuilder(AccountType)
    .ofString('name')
    .ofString('description')
    .ofBoolean('isTaxAdvantaged')
    .ofBoolean('hasMultipleOwners')
    .ofBoolean('isPubliclyAvailable')
    .ofArray('marginTypes', MARGIN_TYPE_DESER, MarginType)
    .toDeSer()

export const ACCOUNT_TYPE_PARSER = ACCOUNT_TYPE_DESER.toParser(AccountType)

export const ACCOUNT_TYPE_UPDATER = ACCOUNT_TYPE_DESER.update

export const ACCOUNT_TYPE_KEY_EXTRACTOR = (helper: JsonHelper) =>
  helper.getString('name')

type ALL_ACCOUNT_TYPES = ACCOUNT_TYPES | BUSINESS_ACCOUNT_TYPES | MARGIN_TYPES

export class PermittedAccountType {
  name: ALL_ACCOUNT_TYPES = ACCOUNT_TYPES.INDIVIDUAL
  reason: string = ''
  isPermitted: boolean = false
  subTypes: PermittedAccountTypeMap = createPermittedAccountTypeMap()
}

export class PermittedAccountTypeCollection extends Array<PermittedAccountType> {}

export type PermittedAccountTypeMap = ArrayMap<string, PermittedAccountType>

export const PERMITTED_ACCOUNT_TYPE_KEY_EXTRACTOR: JsonKeyExtractor<
  ALL_ACCOUNT_TYPES
> = helper => {
  const value = helper.getString('name')
  let rv: ALL_ACCOUNT_TYPES | undefined = _.find(
    ACCOUNT_TYPES,
    v => value === _.snakeCase(v)
  )
  if (!rv) {
    rv = _.find(BUSINESS_ACCOUNT_TYPES, v => value === _.snakeCase(v))
  }
  if (!rv) {
    rv = _.find(MARGIN_TYPES, v => value === _.snakeCase(v))
  }
  return rv!
}

export function createPermittedAccountTypeMap() {
  return ArrayMap.stringKey(PermittedAccountType, 'name')
}

export const PERMITTED_ACCOUNT_TYPE_UPDATER: Updater<PermittedAccountType> = (
  target,
  helper
) => {
  target.name = PERMITTED_ACCOUNT_TYPE_KEY_EXTRACTOR(helper)
  target.reason = helper.getString('reason')
  if (helper.hasField('sub-types')) {
    helper.updateArrayMap(
      'sub-types',
      target.subTypes,
      PERMITTED_ACCOUNT_TYPE_UPDATER,
      PERMITTED_ACCOUNT_TYPE_KEY_EXTRACTOR
    )
    target.isPermitted = target.subTypes.values.some(
      subType => subType.isPermitted
    )
  } else {
    target.isPermitted = helper.getBoolean('is-permitted')
  }
}

export const choiceToAccountTypeMapping: Record<string, ACCOUNT_TYPES> =
  Object.freeze({
    [ACCOUNT_TYPE_CHOICES.INDIVIDUAL_BROKERAGE]: ACCOUNT_TYPES.INDIVIDUAL,
    [JOINT_ACCOUNT_CHOICES.JOINT_TENANTS_IN_COMMON]:
      ACCOUNT_TYPES.JOINT_TENANTS_IN_COMMON,
    [JOINT_ACCOUNT_CHOICES.JOINT_WROS]: ACCOUNT_TYPES.JOINT_WROS,
    [IRA_INDIVIDUAL_ACCOUNT_TYPE_CHOICES.ROTH_IRA]: ACCOUNT_TYPES.ROTH_IRA,
    [IRA_INDIVIDUAL_ACCOUNT_TYPE_CHOICES.TRADITIONAL_IRA]:
      ACCOUNT_TYPES.TRADITIONAL_IRA,
    [IRA_INDIVIDUAL_ACCOUNT_TYPE_CHOICES.SEP_IRA]: ACCOUNT_TYPES.SEP_IRA,
    [IRA_BENEFICIARY_OR_INHERITED_ACCOUNT_TYPE_CHOICES.BENEFICIARY_ROTH_IRA]:
      ACCOUNT_TYPES.BENEFICIARY_ROTH_IRA,
    [IRA_BENEFICIARY_OR_INHERITED_ACCOUNT_TYPE_CHOICES.BENEFICIARY_TRADITIONAL_IRA]:
      ACCOUNT_TYPES.BENEFICIARY_TRADITIONAL_IRA
  })

export const accountTypeToChoiceMapping = Object.freeze(
  Object.fromEntries(
    Object.entries(choiceToAccountTypeMapping).map(([k, v]) => [v, k])
  )
) as Record<ACCOUNT_TYPES, string>

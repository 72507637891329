import type { ComponentProps } from "svelte"
import Contents from "./Contents.svelte"
import Overlay from "./Overlay.svelte"
import Spinner from "./Spinner.svelte"

export type LoaderSpinnerProps = ComponentProps<Spinner>

export const Loader = {
  Overlay: Overlay,
  Spinner: Spinner,
  Contents: Contents,
} as const

<script lang="ts">
  import { Loader } from "@tastyworks/ui-library"

  export let text: string = ""
  export let params: any = null // to support spa-router loadingParams

  $: message = params?.text ?? text

  // copied from https://github.com/Schum123/svelte-loading-spinners/blob/master/src/Circle.svelte
</script>

<Loader.Overlay class="overlay">
  <Loader.Spinner />
  {#if message}
    <div class="text">{message}</div>
  {/if}
</Loader.Overlay>

import {
  IRA_DISTRIBUTION_REASON_VALUES,
  ValidateYup,
  WITHHOLDING_TYPES,
  WITHHOLDING_TYPES_VALUES,
  calculateWithholdingAmount,
  isNilOrEmpty,
} from "@tastyworks/tastyworks-api"
import { boolean, number, object, ref, string } from "yup"
import { maxFederalWithholding, maxStateWithholding } from "../yup/ira-funding"

export const MIN_WITHHOLDING_AMOUNT = 0.01

const { buildSchema, internalCashTransferSchema } = ValidateYup

export class InternalTransferForm {
  accountsValid = false
  amount = null
  destinationAccountNumber = null
  frequency = "One Time"
  contribution = ""
  distribution = ""
  federalWithholdingEnabled = false
  stateWithholdingEnabled = false
  federalWithholding: number | null = null
  federalWithholdingType = WITHHOLDING_TYPES.PERCENT
  stateWithholding: number | null = null
  stateWithholdingType = WITHHOLDING_TYPES.FIXED
  maxTransferAmount = 0
  hasAgreedConversion = false
  isConversion = false
  isReview = false
  withholdingsEnabled = false
  get hasContribution() {
    return !isNilOrEmpty(this.contribution)
  }
  get hasDistribution() {
    return !isNilOrEmpty(this.distribution)
  }
  get federalWithholdingAmount() {
    if (!this.federalWithholdingEnabled) {
      return 0
    }
    return calculateWithholdingAmount(
      this.amount,
      this.federalWithholdingType,
      this.federalWithholding
    )
  }
  get stateWithholdingAmount() {
    if (!this.stateWithholdingEnabled) {
      return 0
    }
    return calculateWithholdingAmount(
      this.amount,
      this.stateWithholdingType,
      this.stateWithholding
    )
  }
}

const validationSchema = internalCashTransferSchema.concat(
  object({
    accountsValid: boolean().required().oneOf([true]),
    // To block continue for ineligible transfers.
    amount: number().required().positive().max(ref("maxTransferAmount")),
    contribution: string().when("isDestinationAccountAnyIra", {
      is: true,
      then: () => string().required(),
    }),
    destinationAccountNumber: string().required(),
    distribution: string()
      .nullable()
      .when("isCurrentAccountAnyIra", {
        is: true,
        then: () => string().required().oneOf(IRA_DISTRIBUTION_REASON_VALUES),
      }),
    federalWithholding: number()
      .nullable()
      .when("federalWithholdingEnabled", {
        is: true,
        then: (fieldSchema) =>
          fieldSchema
            .min(MIN_WITHHOLDING_AMOUNT)
            .test("maxFederalWithholding", maxFederalWithholding)
            .required(),
      }),
    federalWithholdingEnabled: boolean(),
    federalWithholdingType: string().oneOf(WITHHOLDING_TYPES_VALUES),
    hasAgreedConversion: boolean().when(["isConversion", "isReview"], {
      is: true,
      then: (schema) =>
        schema.required().oneOf([true], "yup.common.mixed.required"),
    }),
    hasConfirmedReviewed: boolean().when("withholdingsEnabled", {
      is: true,
      then: () =>
        boolean().required().oneOf([true], "yup.common.mixed.required"),
    }),
    isConversion: boolean(),
    isCurrentAccountAnyIra: boolean().required(),
    isDestinationAccountAnyIra: boolean().required(),
    isReview: boolean(),
    maxTransferAmount: number(),
    stateWithholding: number()
      .nullable()
      .when("stateWithholdingEnabled", {
        is: true,
        then: (fieldSchema) =>
          fieldSchema
            .min(MIN_WITHHOLDING_AMOUNT)
            .test("maxStateWithholding", maxStateWithholding)
            .required(),
      }),
    stateWithholdingEnabled: boolean(),
    stateWithholdingType: string().oneOf(WITHHOLDING_TYPES_VALUES),
    withholdingsEnabled: boolean(),
  })
)

export const internalTransferFormSchema = buildSchema(
  "InternalCashTransferForm",
  validationSchema
)

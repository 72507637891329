import goog from "@tastyworks/boulangerie-bundle"
import { readable } from "svelte/store"

const Observer = goog.module.get("com.dough.boule.Observer")

/**
 * Returns a readable svelte-store observing an old-style boule-property.
 *
 * Think hard about whether or not you want to use this (probably not). Most of
 * the time you're better off converting something in the java-layer to a new-
 * style BouleProperty and using boulePropertyStore.
 */
function _bouleObservableStore(
  bouleObject,
  bouleObjectProperty,
  initialValue,
  nullable
) {
  if (bouleObject === null || bouleObject === undefined) {
    if (!nullable) {
      throw new Error(
        `non-nullable bouleObservableStore was supplied for proeprty ${bouleObjectProperty}`
      )
    }
    return null
  }
  return readable(initialValue, (set) => {
    set(initialValue)
    const observer = Observer.$adapt((event) => set(event.newValue))
    bouleObject.addObserver(bouleObjectProperty, observer)
    return () => bouleObject.removeObserver(bouleObjectProperty, observer)
  })
}

export function bouleObservableStore(
  bouleObject,
  bouleObjectProperty,
  initialValue
) {
  return _bouleObservableStore(
    bouleObject,
    bouleObjectProperty,
    initialValue,
    false
  )
}

export function nullableBouleObservableStore(
  bouleObject,
  bouleObjectProperty,
  initialValue
) {
  return _bouleObservableStore(
    bouleObject,
    bouleObjectProperty,
    initialValue,
    true
  )
}

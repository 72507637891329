import { boulePropertyStore } from "@tastyworks/boulangerie-svelte"
import { derived, get, Readable, writable } from "svelte/store"
import { RELOAD_AFTER_LOGIN_KEY } from "./configure-service-worker"
import {
  accountsStore,
  hasOpenAccounts,
} from "/@/account-management/store/account"
import { initializeStreamingBalances } from "/@/account-management/streamer/account-balance"
import {
  allowUniqueRedirectBypass,
  getAdminAccountNumbers,
  isAdmin,
  LOGIN_PAGE,
  requireValidSession,
  setAccountNumbers,
} from "/@lib/shared"
import { accountStreamer } from "/@lib/tastyworks-rest"

// Flag letting us know that ZVA is ready, updated in app.html with zoomCampaignSdk:ready
// Used to avoiding the case where we try calling functions on ZVA (i.e. close()) prematurely
export const isZVAReady = writable(false)

export const hasDismissedMobileBanner = writable(false) // to be updated by trading download

export function getMobileOS() {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  } else if (/iPhone/.test(ua)) {
    return "iOS"
  }
  return "other"
}

async function accountFetch() {
  try {
    await requireValidSession()
    if (isAdmin()) {
      return getAdminAccountNumbers()
    }

    const accounts = await accountsStore.initialize()

    return accounts.values.map((account) => account.accountNumber)
  } catch (_) {
    /**
     * TODO: [DG] We need to implement a better alert.
     * if we let the error fall through the user will be prompted with "An unexpected error occurred ..."
     * this will be a temporary solution until we have a better alert to tell the user that we didn't detect a valid session
     */
    window.location.href = LOGIN_PAGE
  }
}

function initializeStreamer(accountNumbers) {
  if (accountNumbers && accountNumbers.length > 0) {
    setAccountNumbers(accountNumbers)

    accountStreamer.addStreamerStateObserver(() => {
      if (accountStreamer.isOpen) {
        accountStreamer.subscribeToAccounts()
      }
    })

    initializeStreamingBalances()

    accountStreamer.start()
  }
}

export async function initSession(refreshPage?: () => void): Promise<void> {
  if (allowUniqueRedirectBypass()) {
    return
  }

  const accountNumbers = await accountFetch()
  if (localStorage.getItem(RELOAD_AFTER_LOGIN_KEY) === "true") {
    localStorage.setItem(RELOAD_AFTER_LOGIN_KEY, "false")
    refreshPage()
  }
  initializeStreamer(accountNumbers)
}

export async function loadAndSaveTradingSession(): Promise<any> {
  let twContext = get(_tradeSessionStore)
  if (twContext === null) {
    twContext = await import("/@lib/boulangerie")
    const { twLoginManager, twSessionManager } = twContext
    await twLoginManager.reloadSession().toPromise()
    await twSessionManager.initTradeSession().toPromise()

    _tradeSessionStore.set(twContext)
  }

  return twContext
}

export async function loadTradingSession() {
  if (!get(hasOpenAccounts)) {
    return null
  }

  if (!accountsStore.isInitialized()) {
    await accountsStore.initialize()
  }

  return loadAndSaveTradingSession()
}

const _tradeSessionStore = writable(null)

export const tradeSessionStore: Readable<any> = derived(
  _tradeSessionStore,
  (val) => val
)

export const isPrivacyMode = derived(
  tradeSessionStore,
  ($twContext, set) => {
    if ($twContext === null) {
      return
    }

    const privacyField = $twContext.appSession.accountPreferences.privacyField()
    const privacyStore = boulePropertyStore(privacyField.valueProperty())
    privacyStore.subscribe(set)
  },
  null
)

import * as i18n from 'i18n-iso-countries/index.js'
import iso3166Subdivisions from './iso-3166-2-subdivisions.json' assert { type: 'json' }
import { isNilOrEmpty } from './string'

export type Subdivision = keyof typeof iso3166Subdivisions

/**
 * look up {@link https://en.wikipedia.org/wiki/ISO_3166-2 ISO-3166-2} subdivisions for a given country
 */
export function getSubdivisions(country: string) {
  let countryCode = i18n.alpha3ToAlpha2(country)
  let subdivision = iso3166Subdivisions[countryCode as Subdivision]

  if (isNilOrEmpty(subdivision)) {
    return []
  }
  return subdivision
}

/**
 * look up {@link https://en.wikipedia.org/wiki/ISO_3166-2 ISO-3166-2} subdivision name for a given country and subdivision code
 * @returns {string|undefined} subdivision name or undefined if not found
 */
export function getSubdivisionName(country: string, subdivisionCode: string) {
  const subdivisions = getSubdivisions(country)
  return subdivisions.find(s => s.code === subdivisionCode)?.name
}

export enum AlertSeverity {
  CONFIRM = "confirm",
  ERROR = "error",
  INFO = "info",
  REMIND = "remind",
  IMPORTANT = "important",
  QUOTE_ALERT = "quote_alert",
  ZH_INFO = "zh_info",
  ZH_ERROR = "zh_error",
  ATTENTION = "attention",
}

interface AlertDetailsSpec {
  [severity: string]: {
    headingMessage: string
    icon?: string
  }
}

export const alertDetails: AlertDetailsSpec = {
  [AlertSeverity.CONFIRM]: {
    headingMessage: "CONFIRMATION",
    icon: "Checkmark",
  },
  [AlertSeverity.ERROR]: {
    headingMessage: "ERROR",
    icon: "Cancelled",
  },
  [AlertSeverity.INFO]: {
    headingMessage: "INFORMATION",
    icon: "Info",
  },
  [AlertSeverity.ZH_INFO]: {
    headingMessage: "INFORMATION",
  },
  [AlertSeverity.ZH_ERROR]: {
    headingMessage: "ERROR",
  },
  [AlertSeverity.REMIND]: {
    headingMessage: "REMINDER",
    icon: "Notice",
  },
  [AlertSeverity.IMPORTANT]: {
    headingMessage: "IMPORTANT",
    icon: "Notice",
  },
  [AlertSeverity.QUOTE_ALERT]: {
    headingMessage: "QUOTE ALERT",
    icon: "Alert-Add",
  },
  [AlertSeverity.ATTENTION]: {
    headingMessage: "INFORMATION",
    icon: "Info",
  },
}

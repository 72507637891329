import "/@/assets/styles/all.css"
import { initializeSession, setCustomerAccountIntoSession } from "/@lib/shared"

export function tryInitSessionFromQueryParams() {
  const TASTYWORKS_ACCOUNT = "tastyworks-account"
  const urlParams = new URLSearchParams(window.location.search)
  if (
    removeTokenFromQueryParams(urlParams) &&
    urlParams.has(TASTYWORKS_ACCOUNT)
  ) {
    setCustomerAccountIntoSession(urlParams.get(TASTYWORKS_ACCOUNT))
  }
}

function removeTokenFromQueryParams(urlParams) {
  const possibleTokenParamNames = ["tastyworks-token", "token"]
  for (const paramName of possibleTokenParamNames) {
    if (urlParams.has(paramName)) {
      scrubQueryParamFromUrl(paramName)
      initializeSession(urlParams.get(paramName))
      return true
    }
  }

  return false
}

function scrubQueryParamFromUrl(paramName) {
  const url = new URL(window.location.href)
  url.searchParams.delete(paramName)
  window.history.replaceState(null, "", url.toString())
}

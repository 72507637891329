import {
  COMMUNICATION_CHANNEL_CONSENT_RESPONSE_DESER,
  COMMUNICATION_CHANNEL_CONSENT_UPDATE_DESER,
  CommunicationChannelConsent,
  CommunicationChannelConsentUpdate
} from '../../tastyworks/communication-channel-consents'
import HttpClient from '../../tastyworks/http'
import { updateSingleResponse } from '../../tastyworks/response'
import { JsonHelper } from '../../tastyworks/util/json'

function parseConsentResponse(helper: JsonHelper) {
  const target = new CommunicationChannelConsent()
  return updateSingleResponse(
    helper,
    target,
    COMMUNICATION_CHANNEL_CONSENT_RESPONSE_DESER.update
  )
}

export class CommunicationChannelConsentsService {
  private readonly baseClient: HttpClient
  private readonly customerMeClient: HttpClient

  constructor(baseHttpClient: HttpClient, customerMeClient: HttpClient) {
    this.baseClient = baseHttpClient.nested('communication-channel-consents')
    this.customerMeClient = customerMeClient.nested(
      'communication-channel-consents'
    )
  }

  search(mobilePhoneNumber: string) {
    return this.baseClient
      .postJson('search', {
        'mobile-phone-number': mobilePhoneNumber
      })
      .then(parseConsentResponse)
  }

  update(consent: CommunicationChannelConsentUpdate) {
    return this.customerMeClient
      .put('', COMMUNICATION_CHANNEL_CONSENT_UPDATE_DESER.stringify(consent))
      .then(parseConsentResponse)
  }
}

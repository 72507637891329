import { TwSession } from "@tastyworks/tastyworks-api"
import { TW_SESSION_ID_KEY } from "../shared"

class Session extends TwSession {
  constructor() {
    // FIXME: joshua and I dont know why webPlatformSourceIdentifier fails, investigate later
    super("WB2")
  }

  get currentEmail() {
    return sessionStorage.getItem("tw-email")
  }

  get sessionToken() {
    return sessionStorage.getItem(TW_SESSION_ID_KEY)
  }

  set sessionToken(token) {
    sessionStorage.setItem(TW_SESSION_ID_KEY, token)
  }
}

const session = new Session()

export default session

import goog from "@tastyworks/boulangerie-bundle"
import { tick } from "svelte"
import { readable } from "svelte/store"

const Observer = goog.module.get("com.dough.boule.Observer")

function bouleComputedPropertyStore(property) {
  return readable(property?.get() ?? null, (set) => {
    const observer = Observer.$adapt(async (_event) => {
      await tick()
      set(property.get())
    })
    property?.addBeforeObserver(observer)
    return () => property?.removeBeforeObserver(observer)
  })
}

export default bouleComputedPropertyStore

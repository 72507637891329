import en from "/@/assets/locales/en"
import "/@/assets/styles/all.css"
import { updateTheme } from "/src/theme.js"
import * as Castle from "@castleio/castle-js"
import * as Sentry from "@sentry/browser"
import { getStoredSessionToken, getUserExternalId } from "/@lib/shared"
import { addMessages, getLocaleFromNavigator, init } from "svelte-i18n"
import { MICRO_APP } from "/@/micro-app.js"
import { twApiClient } from "/@lib/tastyworks-rest"
import { initialize } from "/@lib/launch-darkly"
import { tryInitSessionFromQueryParams } from "./try-init-session-from-query-params"
import { initAnalyticsTracking } from "/@/init-analytics.js"

export function initSentry() {
  if (["production", "staging"].includes(import.meta.env.MODE)) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.MODE,
      ignoreErrors: [
        "Invalid Session",
        "Failed to fetch",
        "Failed to connect",
        "Unable to preload",
        "sessionToken not set",
        "The operation is insecure",
      ],
      tracesSampleRate: 1.0,
    })

    const userExternalId = getUserExternalId()
    if (userExternalId) {
      Sentry.setUser({
        id: userExternalId,
      })
    }
  }
}

async function initLaunchDarkly() {
  let user = null

  const token = getStoredSessionToken()

  if (token) {
    const response = await twApiClient.sessionService.validate(token)
    user = response?.data
    await initialize(
      user
        ? {
            kind: "user",
            key: user?.externalId,
            email: user?.email,
            username: user?.username,
            isEmployeeDomain: user?.sessionToken.endsWith("+E"),
          }
        : undefined
    )
  }
}

export default (AppComponent, isMicroApp = false) => {
  initSentry()
  initLaunchDarkly()
  initAppConfig(isMicroApp)

  /**
   * [AM] !!!
   * analytics tracking initialisation must be deferred until after we've
   * sanitised tokens out of the query string so that tokens don't get leaked
   * into snowplow
   */
  tryInitSessionFromQueryParams()
  initAnalyticsTracking(isMicroApp)

  const app = new AppComponent({
    target: document.getElementById("app"),
  })
  window.app = app
  return app
}

export function initAppConfig(isMicroApp = false) {
  MICRO_APP.set(isMicroApp)
  updateTheme()
  Castle.configure({ pk: import.meta.env.VITE_CASTLE_PUBLISHABLE_API_KEY })
  addMessages("en", en)
  init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
  })
}

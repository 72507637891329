import goog from "@tastyworks/boulangerie-bundle"
import dayjs from "dayjs"
import boulePropertyStore, {
  bouleWritablePropertyStore,
} from "./boule-property-store"

const DateTimeUtil = goog.module.get("com.dough.util.DateTimeUtil")
const DateUtil = goog.module.get("com.dough.util.DateUtil")

export function javaToInputDate(value) {
  return DateTimeUtil.formatIsoDate(value)
}

export function inputToJavaDate(value) {
  return DateUtil.parseYearMonthDayWithoutTimeZone(value)
}

// FIXME: ... I'm not sure why this exists. It looks like it does the same
//        thing as javaToInputDate but timezone-aware (so, probably broken in
//        some cases).
export function javaToInputDateFilter(value) {
  return dayjs(value.jsdate).format("YYYY-MM-DD")
}

export function nullableJavaToInputDate(value) {
  return value ? javaToInputDate(value) : null
}

export function nullableInputToJavaDate(value) {
  return value ? inputToJavaDate(value) : null
}

export function javaDateStore(property) {
  return boulePropertyStore(property, nullableJavaToInputDate)
}

export function writableJavaDateStore(property) {
  return bouleWritablePropertyStore(property, {
    read: nullableJavaToInputDate,
    write: nullableInputToJavaDate,
  })
}

// tasty.ui.filter.DateRangePicker svelte-store interface.
export function createDateRangePicker(picker) {
  return Object.freeze({
    clearEnd: () => picker.clearEndDate(),
    clearStart: () => picker.clearStartDate(),
    end: javaDateStore(picker.endDateProperty()),
    max: writableJavaDateStore(picker.maxDateProperty()),
    min: writableJavaDateStore(picker.minDateProperty()),
    pickDate: (d) => picker.pickDate(inputToJavaDate(d)),
    pickMonth: (d) => picker.pickMonth(inputToJavaDate(d)),
    start: javaDateStore(picker.startDateProperty()),
  })
}

import { TransactionStatusBucket } from './cash-management'
import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { IRA_DETAIL_DESER, IraDetail } from './ira-detail'

export enum INTERNAL_CASH_TRANSFER_STATUSES {
  CANCEL_REQUESTED = 'Cancel Requested',
  CLEARING_CANCELED = 'Clearing Canceled',
  CLEARING_COMPLETED = 'Clearing Completed',
  CLEARING_ERROR = 'Clearing Error',
  CLEARING_REJECTED = 'Clearing Rejected',
  PENDING_SUBMISSION = 'Pending Submission to Clearing',
  SUBMITTED_TO_CLEARING = 'Submitted to Clearing'
}

function getStatusBucket(status: INTERNAL_CASH_TRANSFER_STATUSES) {
  switch (status) {
    case INTERNAL_CASH_TRANSFER_STATUSES.CLEARING_COMPLETED:
      return TransactionStatusBucket.COMPLETE
    case INTERNAL_CASH_TRANSFER_STATUSES.CANCEL_REQUESTED:
    case INTERNAL_CASH_TRANSFER_STATUSES.CLEARING_CANCELED:
      return TransactionStatusBucket.CANCELED
    case INTERNAL_CASH_TRANSFER_STATUSES.CLEARING_ERROR:
    case INTERNAL_CASH_TRANSFER_STATUSES.CLEARING_REJECTED:
      return TransactionStatusBucket.REJECTED
    case INTERNAL_CASH_TRANSFER_STATUSES.PENDING_SUBMISSION:
    case INTERNAL_CASH_TRANSFER_STATUSES.SUBMITTED_TO_CLEARING:
    default:
      return TransactionStatusBucket.PROCESSING
  }
}

export class InternalCashTransfer extends RestResource {
  accountNumber = ''
  amount = 0
  createdAt?: Date
  destinationAccountNumber = ''
  iraDetail: IraDetail | null = null
  isCancelable = false
  status = INTERNAL_CASH_TRANSFER_STATUSES.PENDING_SUBMISSION

  get statusBucket(): TransactionStatusBucket {
    return getStatusBucket(this.status)
  }

  get isProcessingStatus() {
    return this.statusBucket === TransactionStatusBucket.PROCESSING
  }

  get isCanceledStatus() {
    return this.statusBucket === TransactionStatusBucket.CANCELED
  }

  get isCompleteStatus() {
    return this.statusBucket === TransactionStatusBucket.COMPLETE
  }
}

export class InternalCashTransferEligibility {
  isEligible = false
  reason = ''
}

export const INTERNAL_CASH_TRANSFER_DESER: SchemaDeSer<InternalCashTransfer> =
  new SchemaDeSerBuilder(InternalCashTransfer)
    .ofString('id')
    .ofString('accountNumber')
    .ofFloat('amount')
    .ofDateTime('createdAt')
    .ofString('destinationAccountNumber')
    .ofNested('iraDetail', IRA_DETAIL_DESER, IraDetail)
    .ofBoolean('isCancelable')
    .ofString('status')
    .toDeSer()

export const INTERNAL_CASH_TRANSFER_ELIGIBILITY_DESER: SchemaDeSer<InternalCashTransferEligibility> =
  new SchemaDeSerBuilder(InternalCashTransferEligibility)
    .ofBoolean('isEligible')
    .ofString('reason')
    .toDeSer()

import { SchemaDeSer, SchemaDeSerBuilder } from './deser'

type Source = 'Account Opening' | 'Customer Account Management'

export class CommunicationChannelConsent {
  mobilePhoneNumber = ''
  optInStatus = false
}

export class CommunicationChannelConsentUpdate {
  optInStatus = false
  // other values maybe possible in the future
  channel = 'SMS' as const
  source: Source = 'Account Opening'
}

export const COMMUNICATION_CHANNEL_CONSENT_RESPONSE_DESER: SchemaDeSer<CommunicationChannelConsent> =
  new SchemaDeSerBuilder(CommunicationChannelConsent)
    .ofString('mobilePhoneNumber')
    .ofBoolean('optInStatus')
    .toDeSer()

export const COMMUNICATION_CHANNEL_CONSENT_UPDATE_DESER: SchemaDeSer<CommunicationChannelConsentUpdate> =
  new SchemaDeSerBuilder(CommunicationChannelConsentUpdate)
    .ofBoolean('optInStatus')
    .ofString('channel')
    .ofString('source')
    .toDeSer()

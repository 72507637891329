<!--  -->
<script lang="ts">
  import { cn } from "#lib/utils"

  type Size = keyof typeof sizeMapping
  const sizeMapping = {
    "x-small": "20px",
    small: "32px",
    medium: "48px",
    large: "64px",
  } as const

  function deriveSize(size: Size): string {
    return sizeMapping[size]
  }

  let sizeProp: Size = "medium"
  export { sizeProp as size }
  let className = ""
  export { className as class }

  $: size = deriveSize(sizeProp)
</script>

<div
  class={cn("loading-spinner bg-transparent", className)}
  role="img"
  aria-label="loading spinner"
  style:height={size}
  style:width={size}
  style:border-width="calc({size} / 16)"
  {...$$restProps}
/>

<style>
  .loading-spinner {
    --primary: var(--color-text-icon-general-primary);
    --secondary: var(--color-background-interaction-field);
    border-color: var(--primary) var(--secondary) var(--primary) var(--primary);
    border-style: solid;
    border-image: initial;
    border-radius: 50%;
    animation: 1s cubic-bezier(0.698, 0.354, 0.502, 0.791) 0s infinite normal
      none running rotate;
  }

  @keyframes rotate {
    0% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
</style>

import { cva } from "class-variance-authority"
import type { ComponentProps } from "svelte"
import Button from "./Button.svelte"

export { Button }
export type ButtonProps = ComponentProps<Button>

const variant = {
  default:
    "bg-button-default text-button-primary hover:bg-button-default-hover",

  blue: "bg-button-blue text-icon-general-inverted hover:bg-button-blue-hovered active:bg-button-blue-hovered",
  green:
    "bg-button-green text-icon-general-inverted hover:bg-button-green-hovered active:bg-button-green-hovered",
  red: "bg-button-red text-icon-general-inverted hover:bg-button-red-hovered active:bg-button-red-hovered",
  orange:
    "bg-button-orange text-icon-general-inverted hover:bg-button-orange-hovered active:bg-button-orange-hovered",
  gold: "bg-button-gold text-icon-general-inverted hover:bg-button-gold-hovered active:bg-button-gold-hovered",

  // Pseudo buttons
  ghost: "hover:bg-accent hover:text-accent-foreground p-0 h-fit w-fit",
  // FIXME: keyboard focus + active state shows no underline, so user receives no interaction feedback
  link: "[disabled]:text-button-inactive text-general-hyperlink focus:underline hover:underline underline-offset-[3px] active:underline active:shadow-none focus:active:underline p-0 h-fit w-fit",

  // Status buttons
  success:
    "hover:bg-button-success-hover border border-alert-success text-alerts-confirmation",
  pending:
    "hover:bg-button-pending-hover border border-alert-pending text-alerts-pending",
  error:
    "hover:bg-button-error-hover border border-alert-error text-alerts-error",
  defaultOutline:
    "hover:bg-button-default hover:text-button-primary border border-general-default text-general-primary-label",

  // deprecated variants
  confirmation:
    "bg-button-confirmation text-button-colored hover:bg-button-confirmation-hover",
  buy: "bg-button-buy text-button-colored hover:bg-button-buy-hover",
  call: "bg-button-call text-button-colored hover:bg-button-call-hover",
  cancel: "bg-button-cancel text-button-colored hover:bg-button-cancel-hover",
  sell: "bg-button-sell text-button-colored hover:bg-button-sell-hover",
  put: "bg-button-put text-button-colored hover:bg-button-put-hover",
  continue:
    "bg-button-continue text-button-colored hover:bg-button-continue-hover",
  reviewAndSend:
    "bg-button-review-and-send text-button-colored hover:bg-button-review-and-send-hover",
  assetType:
    "bg-button-asset-type text-button-colored hover:bg-button-asset-type-hover",
  upgrade:
    "bg-button-upgrade text-button-colored hover:bg-button-upgrade-hover",
  orderStatusWorking:
    "bg-button-order-status-working text-button-colored hover:bg-button-order-status-working-hover",
}

const size = {
  small: "font-medium-550 px-2 h-8",
  medium: "font-medium-550 px-3 h-9",
  large: "font-large-550 px-3 h-11 min-w-[112px]",
  iconSmall: "h-8 w-8 p-1.5",
  iconLarge: "h-10 w-10 p-0",
}

export const buttonVariantKeys = Object.keys(variant)
export const buttonSizeKeys = Object.keys(size)

export const buttonVariants = cva(
  "focus-visible:ring-ring active:shadow-inner-top-medium inline-flex h-10 items-center justify-center rounded px-4 py-2 ring-offset-background transition-colors font-medium-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none",
  {
    variants: {
      variant,
      v2: {
        true: "v2-transitions duration-300 focus-visible:ring-1 focus-visible:ring-semantic-interaction-focused active:scale-95 disabled:bg-button-disabled disabled:text-icon-general-disabled",
        false: "disabled:opacity-50",
      },
      size,
    },
    defaultVariants: {
      variant: "default",
      v2: false,
    },
    compoundVariants: [
      {
        v2: true,
        variant: "default",
        className: "bg-button-primary active:bg-button-primary-hovered",
      },
      {
        v2: true,
        size: ["small", "medium", "large", "iconSmall", "iconLarge"],
        variant: ["ghost", "link"],
        // ghost + link should ignore padding, width, and height from size variants, so this compound variant overwrites size variants
        className: "h-fit w-fit min-w-fit p-0",
      },
      {
        v2: true,
        variant: ["ghost"],
        className: "disabled:bg-transparent",
      },
      {
        v2: true,
        variant: ["default", "ghost"],
        className: "hover:bg-button-primary-hovered",
      },
      {
        v2: true,
        variant: ["call", "buy", "confirmation"],
        className:
          "bg-button-green text-icon-general-inverted hover:bg-button-green-hovered active:bg-button-green-hovered",
      },
      {
        v2: true,
        variant: ["cancel", "sell", "put"],
        className:
          "bg-button-red text-icon-general-inverted hover:bg-button-red-hovered active:bg-button-red-hovered",
      },
      {
        v2: true,
        variant: ["upgrade", "reviewAndSend"],
        className:
          "bg-button-orange text-icon-general-inverted hover:bg-button-orange-hovered active:bg-button-orange-hovered",
      },
      {
        v2: true,
        variant: ["assetType", "continue"],
        className:
          "bg-button-blue text-icon-general-inverted hover:bg-button-blue-hovered active:bg-button-blue-hovered",
      },
      {
        v2: true,
        variant: "orderStatusWorking",
        className:
          "bg-button-gold text-icon-general-inverted hover:bg-button-gold-hovered active:bg-button-gold-hovered",
      },
      {
        v2: true,
        variant: ["success", "pending", "error"],
        className: "hover:text-icon-general-inverted",
      },
      {
        v2: true,
        variant: "success",
        className: "hover:bg-button-green-hovered",
      },
      {
        v2: true,
        variant: "pending",
        className: "hover:bg-button-gold-hovered",
      },
      {
        v2: true,
        variant: "error",
        className: "hover:bg-button-red-hovered",
      },
    ],
  }
)

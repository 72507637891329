import { IRA_DISTRIBUTION_REASONS } from './cash-management'
import { SchemaDeSer, SchemaDeSerBuilder } from './deser'

export class ValidContribution {
  reason = ''
  isPreviousYearContributionAllowed = false
}

export class ValidDistribution {
  reason = ''
  isStateWithholdingEnabled = false
  isFederalWithholdingEnabled = false
}

export class ContributionConstraint {
  onlyAllowCurrentYearContribution = false
  validContributions: ValidContribution[] = []
}

export class DistributionConstraint {
  isTotalDistributionAllowed = false
  validDistributions: ValidDistribution[] = []

  get hasPrematureDistribution() {
    return this.validDistributions
      .map(d => d.reason)
      .includes(IRA_DISTRIBUTION_REASONS.PREMATURE)
  }
}

export enum IraConstraintTransferMethods {
  ACH = 'ACH',
  CHECK = 'CHECK',
  WIRE = 'WIRE'
}

export class IraConstraints {
  isRetirementAccount = false
  contributionConstraints?: ContributionConstraint
  distributionConstraints?: DistributionConstraint
}

export const VALID_CONTRIBUTION_DESER: SchemaDeSer<ValidContribution> =
  new SchemaDeSerBuilder(ValidContribution)
    .ofString('reason')
    .ofBoolean('isPreviousYearContributionAllowed')
    .toDeSer()

export const VALID_DISTRIBUTION_DESER: SchemaDeSer<ValidDistribution> =
  new SchemaDeSerBuilder(ValidDistribution)
    .ofString('reason')
    .ofBoolean('isStateWithholdingEnabled')
    .ofBoolean('isFederalWithholdingEnabled', 'is-withholding-enabled')
    .toDeSer()

export const CONTRIBUTION_CONSTRAINT_DESER: SchemaDeSer<ContributionConstraint> =
  new SchemaDeSerBuilder(ContributionConstraint)
    .ofBoolean('onlyAllowCurrentYearContribution')
    .ofArray('validContributions', VALID_CONTRIBUTION_DESER, ValidContribution)
    .toDeSer()

export const DISTRIBUTION_CONSTRAINT_DESER: SchemaDeSer<DistributionConstraint> =
  new SchemaDeSerBuilder(DistributionConstraint)
    .ofBoolean('isTotalDistributionAllowed')
    .ofArray('validDistributions', VALID_DISTRIBUTION_DESER, ValidDistribution)
    .toDeSer()

export const IRA_CONSTRAINTS_DESER: SchemaDeSer<IraConstraints> =
  new SchemaDeSerBuilder(IraConstraints)
    .ofBoolean('isRetirementAccount')
    .ofNested(
      'contributionConstraints',
      CONTRIBUTION_CONSTRAINT_DESER,
      ContributionConstraint
    )
    .ofNested(
      'distributionConstraints',
      DISTRIBUTION_CONSTRAINT_DESER,
      DistributionConstraint
    )
    .toDeSer()

import { localStorageStore } from "@tastyworks/ui-library"
import { derived, get } from "svelte/store"
import { getCssPropertyValue } from "/@/util"

const system = "system"

// Default light and dark themes, used in System sync
const defaultDark = "dark"
const defaultLight = "light"

// Default theme is dark
const defaultTheme = defaultDark

// NOTE: Key is used as the class name, Value as descriptor
const DARK_THEMES = {
  [defaultDark]: "Dark",
}

const LIGHT_THEMES = {
  [defaultLight]: "Light",
}

const defaultColorblindMode = "None"
export const COLORBLIND_MODES = {
  [defaultColorblindMode]: "",
  Protanopia: "cvd-1",
  Deuteranopia: "cvd-1",
  Tritanopia: "cvd-2",
}

class StringSerializer {
  parse(v) {
    if (v.length === 0) {
      return v
    }

    // I noticed localStorageStore sometimes stores string values with double quotes
    // remove them here just in case we get a bad value
    return v.replaceAll('"', "")
  }

  stringify(v) {
    return v
  }
}

class Theme {
  constructor(name, description) {
    this.name = name
    this.description = description
  }

  get label() {
    return this.description
  }

  get class() {
    return this.name
  }
}

export const themes = setThemes()

function setThemes() {
  const themes = {}
  Object.entries({ ...DARK_THEMES, ...LIGHT_THEMES }).forEach(
    ([key, value]) => (themes[key] = new Theme(key, value))
  )

  themes[system] = new Theme(system, "Sync with system")

  return themes
}

export const colorblindModes = setColorblindModes()

function setColorblindModes() {
  const modes = {}
  Object.entries(COLORBLIND_MODES).forEach(
    ([key, value]) => (modes[key] = new Theme(value, key))
  )

  return modes
}

const systemPrefersLightMediaQuery = "(prefers-color-scheme: light)"
const systemPrefersDarkMediaQuery = "(prefers-color-scheme: dark)"

// Default to dark
export function updateTheme() {
  const userPreferredTheme = get(userPreferredThemeStore)
  const isSystemLight = window.matchMedia(systemPrefersLightMediaQuery).matches

  const currentTheme =
    userPreferredTheme === system
      ? isSystemLight
        ? defaultLight
        : defaultDark
      : userPreferredTheme

  // Remove existing theme classes
  Object.values(themes).forEach((theme) => {
    document.body.classList.remove(theme.name)
  })
  // Add current theme class
  document.body.classList.add(currentTheme)
}

export function updateColorblindMode(userPreferredColorblindMode) {
  // Remove existing theme classes
  Object.values(colorblindModes)
    .filter((mode) => mode.name.length > 0)
    .forEach((mode) => {
      document.body.classList.remove(mode.name)
    })

  if (COLORBLIND_MODES[userPreferredColorblindMode]?.length > 0) {
    document.body.classList.add(COLORBLIND_MODES[userPreferredColorblindMode])
  }
}

const options = { serializer: new StringSerializer() }
export const userPreferredThemeStore = localStorageStore(
  "userPreferredTheme",
  defaultTheme,
  options
)

export const userPreferredColorblindModeStore = localStorageStore(
  "userPreferredColorblindMode",
  defaultColorblindMode,
  options
)

export const isDark = derived(
  userPreferredThemeStore,
  ($userPreferredThemeStore) => {
    if ($userPreferredThemeStore === system) {
      return window.matchMedia(systemPrefersDarkMediaQuery).matches
    }
    return $userPreferredThemeStore in DARK_THEMES
  }
)

export function getChartColors() {
  const upColor = getCssPropertyValue("--color-chart-functional-positive")
  const upArea = getCssPropertyValue("--color-chart-functional-positive-area")
  const downColor = getCssPropertyValue("--color-chart-functional-negative")
  const downArea = getCssPropertyValue("--color-chart-functional-negative-area")
  const downBottom = getCssPropertyValue(
    "--color-chart-functional-negative-area-end"
  )
  const downBright = getCssPropertyValue(
    "--color-chart-functional-negative-area-start"
  )
  const upBottom = getCssPropertyValue(
    "--color-chart-functional-positive-area-end"
  )
  const upBright = getCssPropertyValue(
    "--color-chart-functional-positive-area-start"
  )

  return {
    barTheme: {
      upColor,
      downColor,
    },
    baseLineTheme: {
      lowerSectionStrokeColor: downColor,
      upperSectionStrokeColor: upColor,
      lowerSectionFillColor: downArea,
      upperSectionFillColor: upArea,
    },
    candleTheme: {
      upColor,
      downColor,
      upWickColor: upColor,
      downWickColor: downColor,
    },
    equivolumeTheme: {
      upColor,
      downColor,
    },
    histogram: {
      downBottom,
      downBright,
      downCap: downColor,
      upBottom,
      upBright,
      upCap: upColor,
    },
    labels: {
      volumeLabel: {
        upColor,
        downColor,
      },
    },
    lineTheme: {
      upColor,
      downColor,
    },
  }
}

export const styleColorScheme = derived(userPreferredThemeStore, ($theme) =>
  system === $theme ? "light dark" : $theme
)

userPreferredThemeStore.subscribe(() => {
  updateTheme()
})

window
  .matchMedia(systemPrefersDarkMediaQuery)
  .addEventListener("change", updateTheme)

userPreferredColorblindModeStore.subscribe((userPreferredColorblindMode) => {
  updateColorblindMode(userPreferredColorblindMode)
})

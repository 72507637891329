import { Percent, WITHHOLDING_TYPES } from "@tastyworks/tastyworks-api"

const MAX_PERCENT_WITHHOLDING_AMOUNT = 100

export function maxStateWithholding(value: number, context) {
  const withdrawalAmount = context.parent.amount
  const withholdingType = context.parent.stateWithholdingType
  const federalWithholdingAmount = context.parent.federalWithholding

  let max = 0

  if (!withdrawalAmount) {
    return context.createError({
      message: "Enter a withdrawal amount value",
    })
  }

  if (withholdingType === WITHHOLDING_TYPES.PERCENT) {
    max = MAX_PERCENT_WITHHOLDING_AMOUNT - federalWithholdingAmount
  } else {
    max =
      withdrawalAmount -
      withdrawalAmount * Percent.fromPercent(federalWithholdingAmount).decimal
  }

  return value > max
    ? context.createError({
        message: `Enter a value that is equal to or lower than ${max}`,
      })
    : true
}

export function maxFederalWithholding(value: number, context) {
  const withdrawalAmount = context.parent.amount
  const stateWithholdingType = context.parent.stateWithholdingType
  const stateWithholdingAmount = context.parent.stateWithholding
  let max = 0

  if (!withdrawalAmount) {
    return context.createError({ message: "Enter a withdrawal amount value" })
  }

  if (stateWithholdingType === WITHHOLDING_TYPES.PERCENT) {
    max = MAX_PERCENT_WITHHOLDING_AMOUNT - stateWithholdingAmount
  } else {
    max = ((withdrawalAmount - stateWithholdingAmount) / withdrawalAmount) * 100
  }

  return value > max
    ? context.createError({
        message: `Enter a value that is equal to or lower than ${max}`,
      })
    : true
}

let commonEventData = {}

// simple wrapper to make it clear what we're doing at call points
export function pushSnowplowEvent(tag: string, data: object = {}) {
  window.dataLayer?.push({
    event: tag,
    ...data,
    ...commonEventData,
  })
}

export function updateSnowplowCommonEventData(
  queryParams: URLSearchParams,
  isMicroApp: boolean
) {
  const pcPlatform = "pc"
  const desktopDeviceType = "Desktop"
  const platform =
    queryParams.get("snowplow-platform") ?? (isMicroApp ? pcPlatform : "web")
  const deviceType =
    queryParams.get("snowplow-device-type") ??
    (isMicroApp ? desktopDeviceType : "Web")
  commonEventData = {
    platform,
    device_type: deviceType,
    is_desktop_webview:
      platform === pcPlatform && deviceType === desktopDeviceType,
  }

  if (queryParams.has("snowplow-session-id")) {
    commonEventData["snowplow_session_id"] = queryParams.get(
      "snowplow-session-id"
    )
  }
}

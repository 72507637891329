<script lang="ts">
  import type { BaseProps } from "#lib/internal/types"
  import { cn } from "#lib/utils"

  type $$Props = BaseProps<"div">

  let className: $$Props["class"] = ""
  export { className as class }
</script>

<div
  class={cn(
    "absolute inset-0 z-10 flex h-full w-full flex-col items-center justify-center gap-4 bg-general-blanket text-icon-general-primary",
    // TODO: remove this temporary styles when bg-general-blanket is adjusted
    "bg-[rgba(0,0,0,0.15)] dark:bg-general-blanket",
    className
  )}
  {...$$restProps}
>
  <slot />
</div>

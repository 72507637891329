/* eslint-disable */
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone.js'
import utc from 'dayjs/plugin/utc.js'
import localizedFormat from 'dayjs/plugin/localizedFormat.js'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

export type MONTHS = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export const NEW_YORK_ZONE = 'America/New_York'

export const EXTENDED_TRADING_TIME_MINUTES = 15
export const FUTURES_EXTENDED_TRADING_TIME_MINUTES = 60

const TRADING_END_HOUR_NEW_YORK = 16
const TRADING_END_MINUTES_NEW_YORK = 0
const TRADING_START_HOUR_NEW_YORK = 9
const TRADING_START_MINUTES_NEW_YORK = 30

export const DECADE_YEARS = 10
export const CENTURY_YEARS = 100

// ISO 8601 date format
export const DATE_FORMAT = 'YYYY-MM-DD'
export const MIN_YEAR = 1900
export const MAX_YEAR = 2100

export default class DateHelper {
  static withYearMonthDay(
    year: number,
    month: MONTHS,
    day: number
  ): DateHelper {
    return new DateHelper().setYearMonthDay(year, month, day).clearTime()
  }

  // Thanks, I hate: https://github.com/iamkun/dayjs/issues/1271
  // This is probably somewhat brittle (`is any` implies private API) so it's
  // contained.
  // dayjs-timezone-iana-plugin is common-js; prefer to maintain a small hack.
  private _dateTime: dayjs.Dayjs

  get dateTime(): dayjs.Dayjs {
    return this._dateTime
  }

  private set dateTime(value: dayjs.Dayjs) {
    const tz = (value as any).$x.$timezone
    this._dateTime = tz ? value.tz(tz, true) : value
  }

  constructor(date = new Date()) {
    this._dateTime = dayjs(date).tz(NEW_YORK_ZONE)
  }

  inUTC(): this {
    this.dateTime = this.dateTime.utc()
    return this
  }

  inNewYork(): this {
    this.dateTime = this.dateTime.tz(NEW_YORK_ZONE)
    return this
  }

  toDate(): Date {
    return this.dateTime.toDate()
  }

  get year(): number {
    return this.dateTime.year()
  }

  get dayOfMonth(): number {
    return this.dateTime.date()
  }

  get month(): MONTHS {
    return (this.dateTime.month() + 1) as MONTHS
  }

  get yearOfDecade(): number {
    return this.year % DECADE_YEARS
  }

  get yearOfCentury(): number {
    return this.year % CENTURY_YEARS
  }

  setYear(year: number): this {
    return this.setYearMonthDay(year, this.month, this.dayOfMonth)
  }

  setMonth(month: MONTHS): this {
    return this.setYearMonthDay(this.year, month, this.dayOfMonth)
  }

  toStartOfMonth(): this {
    const startOfMonthDate = this.setYearMonthDay(this.year, this.month, 1)
    return startOfMonthDate.setTime(0, 0)
  }

  toStartOfYear(): this {
    const startOfYearDate = this.setYearMonthDay(this.year, 1, 1)
    return startOfYearDate.setTime(0, 0)
  }

  setYearMonthDay(year: number, month: MONTHS, day: number): this {
    this.dateTime = this.dateTime
      .year(year)
      .month(month - 1)
      .date(day)
    return this
  }

  setTime(hours: number, minute: number, second = 0, millisecond = 0): this {
    this.dateTime = this.dateTime
      .hour(hours)
      .minute(minute)
      .second(second)
      .millisecond(millisecond)
    return this
  }

  clearTime(): this {
    return this.setTime(0, 0)
  }

  toStartOfTradingTime(): this {
    return this.setTime(
      TRADING_START_HOUR_NEW_YORK,
      TRADING_START_MINUTES_NEW_YORK
    )
  }

  toEndOfTradingTime(): this {
    return this.setTime(TRADING_END_HOUR_NEW_YORK, TRADING_END_MINUTES_NEW_YORK)
  }

  toEndOfExtendedTradingTime(): this {
    return this.setTime(
      TRADING_END_HOUR_NEW_YORK,
      TRADING_END_MINUTES_NEW_YORK + EXTENDED_TRADING_TIME_MINUTES
    )
  }

  day(dayOrd: number): this {
    this.dateTime = this.dateTime.day(dayOrd)
    return this
  }

  add(amount: number, unit: dayjs.ManipulateType): this {
    this.dateTime = this.dateTime.add(amount, unit)
    return this
  }

  addYears(years: number): this {
    return this.add(years, 'years')
  }

  addDays(days: number): this {
    return this.add(days, 'days')
  }

  format(pattern: string): string {
    return this.dateTime.format(pattern)
  }

  isBeforeStartOfTrading(): boolean {
    return (
      this.dateTime.hour() < TRADING_START_HOUR_NEW_YORK ||
      (this.dateTime.hour() === TRADING_START_HOUR_NEW_YORK &&
        this.dateTime.minute() < TRADING_START_MINUTES_NEW_YORK)
    )
  }

  isWeekend(): boolean {
    return this.dateTime.day() === 0 || this.dateTime.day() === 6
  }

  latestWeekday(): this {
    switch (this.dateTime.day()) {
      case 0: // Sunday
        return this.addDays(-2)
      case 6: // Saturday
        return this.addDays(-1)
      default:
        return this
    }
  }

  nextWeekday(): this {
    switch (this.dateTime.day()) {
      case 0: // Sunday
        return this.addDays(1)
      case 6: // Saturday
        return this.addDays(2)
      default:
        return this
    }
  }
}

export const TRADING_END_UTC = new DateHelper().toEndOfTradingTime().toDate()
export const TRADING_START_UTC = new DateHelper()
  .toStartOfTradingTime()
  .toDate()

const MILLS_IN_DAY = 86400000

export function isSameDay(left: Date, right: Date) {
  const leftTime = left.getTime()
  const rightTime = right.getTime()

  const leftDay = Math.floor(leftTime / MILLS_IN_DAY)
  const rightDay = Math.floor(rightTime / MILLS_IN_DAY)

  return leftDay === rightDay
}

export function generate18YearOldDateString() {
  return dayjs().utc(true).subtract(18, 'year').format('YYYY-MM-DD')
}

export function formatMediumDate(date: Date): string {
  return dayjs(date).utc().format('ll')
}

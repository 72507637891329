import { getContext, setContext } from "svelte"

export function uniqueContext<T>(key?: any): {
  getContext: () => T
  setContext: (v: T) => T
} {
  if (key === undefined) {
    key = Symbol()
  }

  return {
    getContext: () => getContext<T>(key),
    setContext: (value: T) => setContext<T>(key, value),
  }
}

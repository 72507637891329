import type { LDClient, LDContext } from "launchdarkly-js-client-sdk"
import { initialize as launchDarklyInitialize } from "launchdarkly-js-client-sdk"
import { derived, writable } from "svelte/store"

let launchDarklyClient: LDClient

/**
 * Initializes the LaunchDarkly client with the provided context.
 * If no context is provided, it uses an anonymous key.
 *
 * @param context - The LaunchDarkly context to initialize the client with.
 * @returns The initialized LaunchDarkly client.
 */
export async function initialize(context?: LDContext) {
  if (launchDarklyClient) {
    return launchDarklyClient
  }
  if (!context) {
    context = {
      key: "anonymous",
    }
  }
  const client = launchDarklyInitialize(
    import.meta.env.VITE_LAUNCHDARKLY_SDK_CLIENT,
    context
  )
  await client.waitForInitialization()
  launchDarklyClient = client
  return client
}

/**
 * Retrieves the LaunchDarkly client instance.
 * If the client has already been initialized, it returns the existing instance.
 * Otherwise, it initializes the client and returns the new instance.
 * @returns {Promise<LaunchDarklyClient>} The LaunchDarkly client instance.
 */
async function getClient() {
  if (launchDarklyClient) return launchDarklyClient
  return await initialize()
}

/**
 * Retrieves the value of a feature flag from LaunchDarkly.
 *
 * @param key - The key of the feature flag.
 * @param fnChangeListener - Optional callback function to be called when the feature flag value changes.
 * @returns A Promise that resolves to the value of the feature flag.
 */
async function getFlagValue<T>(
  key: string,
  fnChangeListener?: (param: T) => void,
  defaultValue?: T
): Promise<T> {
  const client = await getClient()
  const flagValue = await client.variation(key, defaultValue ?? false)

  if (fnChangeListener) {
    client.on("change:" + key, fnChangeListener)
  }
  return flagValue
}

/**
 * Options for the FlagStore class.
 *
 * @template T - The type of the flag value.
 * @property {T} defaultValue - The default value for the flag.
 * @property {boolean} realtime - Whether to enable real-time updates for the flag.
 */
type FlagStoreOptions<T> = {
  defaultValue?: T
  realtime?: boolean
}

/**
 * Retrieves the value of a feature flag from the flag store.
 *
 * @template T - The type of the flag value.
 * @param {string} key - The key of the feature flag.
 * @param {FlagStoreOptions<T>} options - The options for retrieving the flag value.
 * @returns {Writable<T | undefined>} - The writable store containing the flag value.
 */
export function flagStore<T>(key: string, options: FlagStoreOptions<T> = {}) {
  const { defaultValue, realtime } = options
  const store = writable(defaultValue || undefined)
  getFlagValue(key, realtime ? store.set : undefined, defaultValue)
    .then(store.set)
    .catch((error) => {
      console.warn("LaunchDarkly not configured", error)
    })

  return store
}

/**
 * A set of flag values based on launchdarkly defaults for {@link https://docs.launchdarkly.com/home/flags/migration?site=launchDarkly|multi-step migration}
 *
 */
export enum MigrationFlag {
  OFF = "off",
  ON = "complete",
}

const MIGRATION_FLAG_STORE_DEFAULT_OPTIONS: FlagStoreOptions<MigrationFlag> = {
  defaultValue: MigrationFlag.OFF,
  realtime: false,
}

/**
 * Create a flag store for a migration flag represented as a boolean.
 *
 * @param key The key of the feature flag.
 * @param {FlagStoreOptions<T>} options The options for retrieving the flag value.
 * @returns {Readable<boolean>} The derived store containing the boolean flag value.
 */
export function migrationFlagStore(
  key: string,
  options: FlagStoreOptions<MigrationFlag> = {}
) {
  return derived(
    flagStore(key, { ...MIGRATION_FLAG_STORE_DEFAULT_OPTIONS, ...options }),
    ($flag) => $flag === MigrationFlag.ON
  )
}

function mailtoLink(email: string) {
  return `<a class="text-general-hyperlink" href="mailto:${email}">${email}</a>`
}
function learnMoreLink(url: string) {
  return `<a class="text-general-hyperlink" rel="noopener noreferrer" target="_blank" href="${url}">Learn more</a>`
}

export const SUPPORT_EMAIL = "support@tastytrade.com"
export const MAIL_TO_SUPPORT = `mailto:${SUPPORT_EMAIL}`
export const MAIL_TO_SUPPORT_LINK = mailtoLink(SUPPORT_EMAIL)
export const BANKING_EMAIL = "banking@tastytrade.com"
export const MAIL_TO_BANKING = `mailto:${BANKING_EMAIL}`
export const MAIL_TO_BANKING_LINK = mailtoLink(BANKING_EMAIL)

export const RTP_HELP_CENTER =
  "https://support.tastytrade.com/support/s/solutions/articles/Real-Time-Payments"
export const RTP_HELP_CENTER_LINK = learnMoreLink(RTP_HELP_CENTER)

import { toast } from "@zerodevx/svelte-toast"
import { location } from "svelte-spa-router"
import { registerSW } from "virtual:pwa-register"
import ReloadAlertMessage from "./ReloadAlertMessage.svelte"
import { AlertSeverity } from "/@/control/alert"
import { webPushEnabled } from "/@/trading/web-push"
import { checkForSwUpdate } from "/@/util/sw"

export const SOURCE_LOGIN = "login"
export const RELOAD_AFTER_LOGIN_KEY = "reloadAfterLogin"

export function configureServiceWorker(source: string) {
  let activeToastId = undefined
  const updateServiceWorker = registerSW({
    onNeedRefresh() {
      console.log("SW needs refresh")

      if (source === SOURCE_LOGIN) {
        localStorage.setItem(RELOAD_AFTER_LOGIN_KEY, "true")
      } else if (activeToastId === undefined) {
        activeToastId = toast.push({
          classes: [AlertSeverity.INFO],
          component: {
            props: {
              updateServiceWorker,
            },
            sendIdTo: "toastId",
            src: ReloadAlertMessage,
          },
          dismissable: false,
          initial: 1,
          next: 1,
          onpop: () => {
            activeToastId = undefined
          },
          theme: {
            "--toastPadding": "0",
            "--toastBorder": "none",
            "--toastBackground": "transparent",
            "--toastWidth": "400px",
          },
        })
      }
    },
    onOfflineReady() {
      console.log("service worker offline ready")
    },
    onRegisteredSW(
      _swScriptUrl: string,
      registration: ServiceWorkerRegistration
    ) {
      if (!registration) {
        return
      }

      console.log("service worker registered")

      registration.pushManager.getSubscription().then((subscription) => {
        webPushEnabled.set(!!subscription)
      })

      location.subscribe(async () => {
        await checkForSwUpdate(registration)
      })
    },
    onRegisterError(error) {
      console.log("service worker registration error", error)
    },
  })

  return updateServiceWorker
}

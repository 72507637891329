let checkSwUpdate = false

export async function checkForSwUpdate(registration) {
  if (checkSwUpdate) {
    return
  }

  try {
    checkSwUpdate = true
    await registration.update()
    checkSwUpdate = false
  } catch (e) {
    console.error("service worker update failed", e)
  }
}
